import "@fontsource/cinzel";
import "@fontsource/roboto";

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `We've updated the site! ` +
      `Please reload for the new version.`
  );
  if (answer === true) {
    window.location.reload()
  }
}
